import * as React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { mainContainer } from "./index.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const IndexPage = () => {
  return (
    <div className={mainContainer}>
      <Navbar />
      <Container fluid>
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              flexWrap: "wrap",
              padding: "0 10px",
              height: "70vh",
              backgroundColor: "#ffffff",
            }}
          >
            <h2>
              This page was not found.{" "}
              <a href="https://gallery.dyspatch.io/">Go back</a>
            </h2>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default IndexPage;
export const Head = () => <title>Dyspatch Block Gallery</title>;
